import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { generateAllowedTraceUrls } from './helpers';

export const initDataDog = () => {
  const {
    REACT_APP_ENVIRONMENT,
    REACT_APP_DATADOG_APPLICATION_ID,
    REACT_APP_DATADOG_CLIENT_TOKEN,
    REACT_APP_DATA_DOG_SERVICE_NAME,
    REACT_APP_DATADOG_VERSION,
    REACT_APP_DONT_CALL_THIRD_PARTY_SCRIPTS
  } = process.env;

  if (
    // eslint-disable-next-line quotes
    REACT_APP_DONT_CALL_THIRD_PARTY_SCRIPTS === "true" ||
    !REACT_APP_DATADOG_APPLICATION_ID ||
    !REACT_APP_DATADOG_CLIENT_TOKEN ||
    !REACT_APP_ENVIRONMENT
  ) {
    return;
  }

  datadogRum.init({
    applicationId: REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: REACT_APP_DATA_DOG_SERVICE_NAME,
    env: REACT_APP_ENVIRONMENT,
    version: REACT_APP_DATADOG_VERSION || '1.0.0',
    sessionSampleRate: 20,
    sessionReplaySampleRate: 25,
    trackInteractions: true,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
    allowedTracingUrls: generateAllowedTraceUrls(),
    enableExperimentalFeatures: []
  });

  datadogLogs.init({
    clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
    version: REACT_APP_DATADOG_VERSION,
    service: REACT_APP_DATA_DOG_SERVICE_NAME,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: 100
  });
};
