import { datadogLogs } from '@datadog/browser-logs';
import {
  DEFAULT_DD_LOG_MESSAGE,
  DEFAULT_DD_LOG_OPTIONS,
  DataDogLogLevels
} from './constants';

const log = (
  level,
  message = DEFAULT_DD_LOG_MESSAGE,
  { context = '', metadata = {} } = DEFAULT_DD_LOG_OPTIONS
) => {
  if (!message) {
    return false;
  }

  const contextTag = context ? `[${context}] ` : '';

  return datadogLogs.logger[level](`${contextTag}${message}`, {
    ...metadata
  });
};

const buildLogger =
  (level = DataDogLogLevels.INFO) =>
    (message = DEFAULT_DD_LOG_MESSAGE, options = DEFAULT_DD_LOG_OPTIONS) =>
      log(level, message, options);

export const logger = {
  debug: buildLogger(DataDogLogLevels.DEBUG),
  info: buildLogger(DataDogLogLevels.INFO),
  warn: buildLogger(DataDogLogLevels.WARN),
  error: buildLogger(DataDogLogLevels.ERROR)
};
