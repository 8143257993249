export const DataDogLogLevels = Object.freeze({
  DEBUG: 'debug',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error'
});

export const DEFAULT_DD_LOG_MESSAGE = '';

export const DEFAULT_DD_LOG_OPTIONS = {
  context: '',
  metadata: {}
};
