const generateAllowedTraceUrls = () => {
  const {
    REACT_APP_HERMES_URL,
    REACT_APP_MDO_URL,
    REACT_APP_GREENCARE_URL,
    REACT_APP_AUTH_URL,
    REACT_APP_SERVICE_DESK,
    REACT_APP_ADMIN_URL
  } = process.env;

  return (
    [
      REACT_APP_HERMES_URL,
      REACT_APP_MDO_URL,
      REACT_APP_GREENCARE_URL,
      REACT_APP_AUTH_URL,
      REACT_APP_SERVICE_DESK,
      REACT_APP_ADMIN_URL
    ]
      // Prevents to fetch an undefined or empty values.
      .filter((traceUrl) => traceUrl !== 'undefined' && traceUrl)

      // This final slash prevents not desired matches like /autofin matching /autofin-comissions
      .map((traceUrl) => `${traceUrl}/`)

      // datadog propagatorType will use X-Datadog headers, avoiding CORS issues
      .map((traceUrl) => ({ match: traceUrl, propagatorTypes: ['datadog'] }))
  );
};

export { generateAllowedTraceUrls };
